.p-progressbar-value,
.p-progressbar-value-animate {
  background-color: rgb(50, 56, 178) !important;
}

.data-section-row {
  @apply flex flex-row gap-5 flex-wrap;
}

td {
  button {
    margin-right: 0px !important;
    margin-left: 90%;
  }
}
